.country-list {
  min-width: 74px !important;
  text-align: center !important;
  background: rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(16px) !important;
  border-radius: 0px 0px 12px 12px !important;
  top: 30px !important;
}
.country-list > .country {
  padding: 10px !important;
}
.country-list > .country:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}
.react-tel-input .country-list .country.highlight {
  background: transparent !important;
  color: #a66e49 !important;
}
.country-list > .country > span {
  display: none !important;
}
.react-tel-input .country-list {
  width: fit-content !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
  border-radius: 0 !important;
}
.flag-dropdown {
  border-radius: 12px !important;
}
.flag-dropdown.open {
  border-radius: 12px 12px 0px 0px !important;
}
.react-tel-input .country-list::-webkit-scrollbar {
  display: none !important;
}
.react-tel-input .form-control {
  background: rgba(89, 80, 74, 0.4) !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25),
    inset 1px -1px 1px rgba(160, 123, 100, 0.2),
    inset -1px 1px 1px rgba(255, 255, 255, 0.1) !important;
  border: none !important;
  border-radius: 12px !important;
  width: 100%!important;
  height: 64px !important;
  padding-left: 96px !important;

  font-family: 'CircularStd_Book';
  font-style: normal;
  font-weight: 450;
  font-size: 17px;
  line-height: 20px;
  color: var(--text300);
}
.react-tel-input .flag-dropdown {
  background: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
  height: 40px !important;
  top: 50% !important;
  left: 12px !important;
  transform: translateY(-50%) !important;
}
.react-tel-input .flag-dropdown > .selected-flag {
  min-width: 74px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.react-tel-input .selected-flag {
  padding: 0 !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:active {
  background: transparent !important;
}
.react-tel-input .selected-flag .flag {
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.react-tel-input .country-list {
  margin: 10px 0 10px 0 !important;
}

.selected-flag > .flag > .arrow {
  border: none !important;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5 1.875L8.25 8.125L2 1.875' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat !important;
  background-size: 10px !important;
  width: 12px !important;
  height: 8px !important;
  margin-left: 8px !important;
}
.selected-flag > .flag {
  margin-left: -10px !important;
}
