@font-face
  font-family: 'CircularStd_Black'
  src: url('/fonts/CircularStd/Black.otf') format('opentype')

@font-face
  font-family: 'CircularStd_Bold'
  src: url('/fonts/CircularStd/Bold.otf') format('opentype')

@font-face
  font-family: 'CircularStd_Book'
  src: url('/fonts/CircularStd/Book.otf') format('opentype')

@font-face
  font-family: 'CircularStd_Medium'
  src: url('/fonts/CircularStd/Medium.otf') format('opentype')

@font-face
  font-family: 'Rachel_Italic'
  src: url('/fonts/Rachel/Italic.ttf') format('truetype')

@font-face
  font-family: 'Rachel_Roman'
  src: url('/fonts/Rachel/Roman.ttf') format('truetype')

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // 

@mixin F10Bold
  font-family: 'CircularStd_Bold'
  font-size: 1rem
  line-height: 1.2rem
  letter-spacing: .07em

@mixin F10BoldAA
  font-family: 'CircularStd_Bold'
  font-size: 1rem
  line-height: 1rem

@mixin F14BookAA
  font-family: 'CircularStd_Book'
  font-size: 1.4rem
  line-height: 3rem
  letter-spacing: 0.06em
  text-transform: uppercase

@mixin F14Book
  font-family: 'CircularStd_Book'
  font-size: 1.4rem
  line-height: 2rem

@mixin F14BlackAA
  font-family: 'CircularStd_Bold'
  font-size: 1.4rem
  line-height: 3rem
  letter-spacing: 0.06em
  text-transform: capitalize

@mixin F15Bold
  font-family: 'CircularStd_Bold'
  font-size: 1.5rem
  line-height: 2rem

@mixin F20Bold
  font-family: 'CircularStd_Bold'
  font-size: 2rem
  line-height: 3.2rem
  text-transform: capitalize

@mixin F20BoldAA
  font-family: 'CircularStd_Bold'
  font-size: 2rem
  line-height: 3.2rem
  letter-spacing: 0.1em
  text-transform: uppercase

@mixin F20Book
  font-family: 'CircularStd_Book'
  font-size: 2rem
  line-height: 2.8rem

@mixin F13BoldAA
  font-family: 'CircularStd_Bold'
  font-size: 1.3rem
  line-height: 2rem
  letter-spacing: 0.06em
  text-transform: uppercase

@mixin F13Bold
  font-family: 'CircularStd_Bold'
  font-size: 1.3rem
  line-height: 2rem
  letter-spacing: 0.07em

@mixin F17BoldAA
  font-family: 'CircularStd_Bold'
  font-size: 1.7rem
  line-height: 2rem
  letter-spacing: 0.03em
  text-transform: uppercase
  text-align: right

@mixin F17Bold
  font-family: 'CircularStd_Bold'
  font-size: 1.7rem
  line-height: 2.4rem
  text-align: center

@mixin F17Medium
  font-family: 'CircularStd_Bold'
  font-size: 1.7rem
  line-height: 2.4rem

@mixin F12BoldAA
  font-family: 'CircularStd_Bold'
  font-size: 1.2rem
  line-height: 1.6rem
  letter-spacing: 0.1em
  text-transform: uppercase

@mixin F12Medium
  font-family: 'CircularStd_Book'
  font-size: 1.2rem
  line-height: 1.5rem

@mixin F12Book
  font-family: 'CircularStd_Book'
  font-size: 1.2rem
  line-height: 2em
  letter-spacing: 0.07px

@mixin F10Medium
  font-family: 'CircularStd_Book'
  font-size: 1rem
  line-height: 1rem

@mixin F20Medium
  font-family: 'CircularStd_Book'
  font-size: 2rem
  line-height: 2rem

@mixin F24Medium
  font-family: 'CircularStd_Book'
  font-size: 2.4rem
  line-height: 3.2rem

@mixin F15MediumAA
  font-family: 'CircularStd_Book'
  font-size: 1.5rem
  line-height: 2rem
  letter-spacing: 0.04em
  text-transform: uppercase

@mixin F15Medium
  font-family: 'CircularStd_Book'
  font-size: 1.5rem
  line-height: 1.8rem

@mixin F30Medium
  font-family: 'CircularStd_Book'
  font-size: 3rem
  line-height: 3.2rem

@mixin F17Book
  font-family: 'CircularStd_Book'
  font-size: 1.7rem
  line-height: 2.4rem

@mixin F13Book
  font-family: 'CircularStd_Book'
  font-size: 1.3rem
  line-height: 2rem

@mixin F13MediumAA
  font-family: 'CircularStd_Book'
  font-size: 1.3rem
  line-height: 2rem
  letter-spacing: 0.05em
  text-align: center
  text-transform: uppercase

@mixin F13Medium
  font-family: 'CircularStd_Book'
  font-size: 1.3rem
  line-height: 2rem

@mixin F15Book
  font-family: 'CircularStd_Book'
  font-size: 1.3rem
  line-height: 2rem

@mixin Rachel30AA
  font-family: 'Rachel_Roman'
  font-size: 3rem
  height: 4.8rem
  letter-spacing: 0.1em
  text-transform: uppercase
  font-weight: inherit

@mixin Rachel64
  font-family: 'Rachel_Roman'
  font-size: 6.4rem
  letter-spacing: 0.05em

@mixin Rachel40
  font-family: 'Rachel_Roman'
  font-size: 4rem
  line-height: 4.8rem

@mixin Rachel48
  font-family: 'Rachel_Roman'
  font-size: 4.8rem
  line-height: 6.4rem

@mixin Rachel32
  font-family: 'Rachel_Roman'
  font-size: 3.2rem
  line-height: 3.2rem
  letter-spacing: 0.01em
  text-transform: capitalize

@mixin Rachel20
  font-family: 'Rachel_Roman'
  font-size: 2rem
  line-height: 3.4rem
  letter-spacing: 0.05em

@mixin Rachel24
  font-family: 'Rachel_Roman'
  font-size: 2.4rem
  line-height: 3rem
  letter-spacing: 0.02em
  text-transform: capitalize

@mixin Rachel24AA
  font-family: 'Rachel_Roman'
  font-size: 2.4rem
  line-height: 3rem
  letter-spacing: 0.09em
  text-transform: uppercase

@mixin Quote20
  font-family: 'CircularStd_Book'
  font-size: 2rem
  line-height: 2.2rem

@mixin Quote13
  font-family: 'CircularStd_Book'
  font-size: 1.3rem
  line-height: .1rem

@mixin additionalTipText
  font-family: 'CircularStd_Book'
  font-style: normal
  font-weight: normal
  font-size: 1.2rem
  line-height: 1.6rem
