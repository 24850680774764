// includes
@import './inc/colors'
@import './inc/fonts'
@import './inc/consts'
// 

*,
*:after,
*:before
  margin: 0
  padding: 0
  box-sizing: border-box

@media print
  @page
    size: 'A4'
    margin: 0mm !important

html
  font-size: 10px
  background: var(--text50)

  touch-action: manipulation

  scroll-behavior: smooth

  @media ( max-width: $mobile )
    font-size: 8px
  @media ( max-width: $mobile - 400px )
    font-size: 5px

button
  cursor: pointer
  background: transparent
  border: none
  outline: none
  &:disabled
    cursor: not-allowed

input
  background: transparent
  border: none
  outline: none

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
input[type=number]
  -moz-appearance: textfield

ul
  list-style: none

a[aria-line="off"]
  text-decoration: none

a[href^=tel]
  color: inherit
  text-decoration: none

.html2canvas-container 
  width: 100% !important
  height: 100% !important