:root
  --stepsBg: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.2) 100%)
  --bottomBarBg: radial-gradient(63.23% 85.19% at 49.03% -4.94%, #6F5341 0%, #2A1F18 100%)
  
  --bgHome: #252323
  --bgSearch: #131313

  --action50: #F4E9E1
  --action100: #ECDDD3
  --action200: #DCC1B0
  --action300: #CBA58D
  --action400: #B98A6A
  --action500: #A66E49
  --action600: #915F3E
  --action700: #7B4F33
  --action800: #644028
  --action900: #4E311E

  --text50: #EAEAEB
  --text100: #D4D4D6
  --text200: #AFB0B3
  --text300: #908F8C
  --text400: #67686F
  --text500: #474749
  --text600: #3D3D3F
  --text700: #333335
  --text800: #29292B
  --text900: #141415

  --white100: #FFFFFF
  --white60: rgba(255, 255, 255, 0.6)
  --white40: rgba(255, 255, 255, 0.4)
  --white20: rgba(255, 255, 255, 0.2)

  --green100: #07C540
  --green60: rgba(0, 182, 122, 0.6)
  --greenDark: #005128
  --greenDark2: #008F60
  --greenGrad: linear-gradient(180deg, rgba(0, 182, 122, 0.2) 0%, rgba(0, 182, 122, 0.06) 100%)
  --greenGrad2: linear-gradient(180deg, rgba(0, 182, 122, 0.8) 0%, rgba(0, 182, 122, 0.24) 100%)

  --fadeCard: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.23) 100%)
  --fadePage: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)
  --fadeWhiteBottom: linear-gradient(180deg, rgba(255, 255, 255, 0) 15.29%, rgba(255, 255, 255, 0.98) 67.68%)
  --fadeWhiteBottom2: linear-gradient(180deg, rgba(255, 255, 255, 0) 36.55%, rgba(255, 255, 255, 0.98) 100%)
  --fadeSearch1: linear-gradient(180deg, rgba(19, 19, 19, 0) 0%, #131313 76.54%)
  --fadeSearch2: linear-gradient(180deg, rgba(55, 40, 30, 0.6) 0%, rgba(0, 0, 0, 0.394461) 34.81%, rgba(72, 59, 51, 0) 100%)
  --fadeSearch3: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)
  --fadeSearchResult: linear-gradient(180deg, rgba(19, 19, 19, 0) 0%, #131313 75.72%, #131313 92.31%)
  --fadeCalendarWhite: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.92) 39.56%)

  --gradientIconBrown: linear-gradient(180deg, rgba(224, 186, 162, 0.6) 0%, rgba(214, 169, 141, 0.21) 100%)

  --gradCompactInputTop: linear-gradient(180deg, #2A2A2A 0%, #312924 100%)
  
  --cardBg: rgba(74, 69, 69, 0.7)
  --cardBg2: rgba(74, 69, 69, 0.3)
  --cardBgFocus: rgba(74, 69, 69, 0.9)

  --systemFrame: #7B61FF

  --buttonIconBg: rgba(166, 110, 73, 0.4)
  --buttonIconBg2: rgba(185, 131, 96, 0.53)
  --buttonIconBg90: rgba(166, 110, 73, 0.9)
  --buttonIconBg100: #AA714D
  --buttonIconBgVert: linear-gradient(180deg, rgba(120, 104, 95, 0.6) 0%, rgba(120, 104, 95, 0.312) 100%)
  --buttonIconBgHover: rgba(170, 113, 77, 0.8)
  --buttonIconBgFocus: rgba(122, 81, 55, 0.8)

  --inputRouteHighlight: #F6E8DF
  --inputBgDef: rgba(89, 80, 74, 0.4)
  
  --radial1: radial-gradient(40.78% 54.94% at 49.03% -4.94%, #271D17 0%, #2A1F18 100%)
  --radial2: radial-gradient(70.31% 46.4% at 49.28% 76.27%, #6F5341 0%, #252323 100%)
  --radial4: radial-gradient(65.68% 86.16% at 51.21% 100%, #6F5341 0%, #2A1F18 100%)
  --radial6: radial-gradient(101.68% 94.2% at 49.11% 94.2%, #6F5341 0%, #443328 100%)
  --radialProtection: radial-gradient(77.14% 77.14% at 31.27% 14.16%, #CBA58D 0.08%, #8C5D40 100%)

  --additionalCyan: #00A0B6
  --additionalBlue: #331AAD
  --additionalPurple: #B52566
  --additionalRed: #AB212B
  --additionalProtectionDollae: linear-gradient(180deg, rgba(255, 255, 255, 0.87) 0%, rgba(255, 255, 255, 0.79) 100%)
  --additionalTipBg: #E7DBC6
  --additionalTipText: #B87D0A
  --additionalHighlight: #FAF1EB