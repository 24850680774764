.react-calendar {
  width: 100% !important;
  color: #474749 !important;
  border: none !important;
  background: transparent !important;
}
.react-calendar button {
  width: 4.4rem !important;
  height: 4.4rem !important;
}
.react-calendar__tile--active,
.react-calendar__tile:focus {
  background: #141415 !important;
  border-radius: 1.2rem;
  color: #f4e9e1 !important;
}
.react-calendar__month-view__weekdays {
  display: none !important;
}
.react-calendar__tile--now {
  background: transparent !important;
}
.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}
